import React from 'react';
import { Container, Row, Col, Button, Stack } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import './Hero.css';
import { Link } from 'react-scroll';
import { ReactTyped } from "react-typed";

const App_Hero = () => {
  return (
    <div className="hero-section">
      <Container fluid>
        <Row style={{color: 'black'}} className=''>
          <Col md={7} className="text-md-left p-5">
            <Row>
              <Stack style={{textAlign:'left', paddingTop: '50px'}}>
                <img className='img-fluid phone-app-img' src="phone-app.png" alt="Phone App"></img>
                <h2 className="hero-title">Rewards System</h2>
                <h3>for your <span style={{color: '#854941'}}>
                  <ReactTyped strings={["Business", "Café", "Bakery"]} typeSpeed={150} backSpeed={100} loop />
                </span></h3>
                <p className="hero-subtitle">
                    <span style={{fontWeight:'bold', fontSize:'20px'}}>Unlock the potential of your business with our tailored software solutions.</span> 
                    <div style={{fontSize: '18px'}}>
                      Increase customer retention, drive repeat purchases, and boost engagement through a seamless rewards system designed for your business. Discover how our solutions can transform your customer relationships and grow your business.
                    </div>
                </p>
                <Link
                  to="contact"  // ID of the section to scroll to
                    smooth={true}  // Smooth scrolling effect
                    duration={100}>
                      <Button style={{width: '50%', alignContent: 'center', alignSelf: 'center', color:'brown', border: "1px solid black", borderRadius: "10px"}} variant="outline-secondary" size="lg" className="mt-3 align-content-center justify-content-center hero-button ">
                        Book a Demo <FaArrowRight />
                      </Button>
                </Link>
              </Stack>
            </Row>
          </Col>
          <Col className='d-none d-md-block justify-content-center align-content-center p-5'>
            <img style={{transform: 'scale(0.8)'}} className='img-fluid' src="phone-app.png" alt="Phone App"></img>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App_Hero;