
import Lottie from 'lottie-react';
import { useState, useEffect } from 'react';

const AnimationLoader = ({animationFile, style}) => {
  const animationContext = require.context('../../img/animations', false, /\.json$/);
  const [animationData, setAnimationData] = useState(null);
  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const animationData = animationContext(`./${animationFile}`);
        setAnimationData(animationData);
      } catch (error) {
        console.error('Error fetching animation data:', error);
      }
    };

    fetchAnimationData();
  }, [animationFile]);

  return (
    <div style={style}>
      {animationData && <Lottie animationData={animationData} loop={true} autoplay={true} />}
    </div>
  );
};

export default AnimationLoader;