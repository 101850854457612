import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import './ProductSection.css';

const ProductComponent = () => {
  return (
    <Container fluid className="p-5" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: '#FEFAF6'}}>
        <Row className="p-5 justify-content-center">
        <h1 className="text-center mb-4">Our Product Offerings</h1>
        <hr className='text-center' style={{width: '30%', border: '2px solid #854941', alignSelf: 'center!important'}}/>
        </Row>
      <Row>
        <Col md={12} sm={12} lg={4} className="mb-4">
          <Card style={{border: 'none', backgroundColor: '#FEFAF6'}}>
            <Card.Img variant="top" src="phone-app-s1.png" className="card-img-sm" />
            <hr style={{width: '30%', alignSelf: 'center', border: '2px solid #854941'}}/>
            <Card.Body>
              <Card.Title>Customized Reward App</Card.Title>
              <Card.Text style={{fontSize: '18px'}}>
                Enhance customer loyalty with a personalized reward app tailored for your business. 
                With points tracking, special offers, promotions and more.
              </Card.Text>
               <Link
                  to="contact"  // ID of the section to scroll to
                    smooth={true}  // Smooth scrolling effect
                    duration={100}>
                      <Button variant="outline-secondary" size="lg" className="mt-3 align-content-center responsive-button justify-content-center">
                       Contact Us
                      </Button>
                </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Business App Card */}
        <Col md={12} sm={12} lg={4} className="mb-4">
          <Card style={{border: 'none', backgroundColor: '#FEFAF6'}}>
            <Card.Img variant="top" src="ipad-app-s1.png" className="card-img-sm"/>
            <hr style={{width: '30%', alignSelf: 'center', border: '2px solid #854941'}}/>
            <Card.Body>
              <Card.Title>Business App</Card.Title>
              <Card.Text style={{fontSize: '18px'}}>
                Manage your business on the go with our app. Customize settings, integrate seamlessly with your POS system, 
                and get real-time insights to optimize your operations.
              </Card.Text>
               <Link
                  to="contact"  // ID of the section to scroll to
                    smooth={true}  // Smooth scrolling effect
                    duration={100}>
                      <Button variant="outline-secondary" size="lg" className="mt-3 align-content-center responsive-button justify-content-center">
                       Contact Us
                      </Button>
                </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Website and Marketing Support Card */}
        <Col md={12} sm={12} lg={4} className="mb-4">
          <Card style={{border: 'none', backgroundColor: '#FEFAF6'}}>
            <Card.Img variant="top" src="website-mac.png" className="card-img-sm"/>
            <hr style={{width: '30%', alignSelf: 'center', border: '2px solid #854941'}}/>
            <Card.Body>
              <Card.Title>Website & Marketing Support</Card.Title>
              <Card.Text style={{fontSize: '18px'}}>
                Get a professional website for your business with marketing support to help you attract and retain customers. 
                Our team will ensure your online presence is strong and effective.
              </Card.Text>
              <Link
                  to="contact"  // ID of the section to scroll to
                    smooth={true}  // Smooth scrolling effect
                    duration={100}>
                      <Button variant="outline-secondary" size="lg" className="mt-3 align-content-center responsive-button justify-content-center">
                        Contact Us
                      </Button>
                </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductComponent;
