import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert} from 'react-bootstrap';
import { sendEmail } from '../../services/api-service.js';
import './Contact.css'


function AlertDismissible({alertContent, variant}) {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant={variant} onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{alertContent}</Alert.Heading>
      </Alert>
    );
  }
}

const App_ContactForm = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    firstName : '',
    lastName: '',
    email: '',
    businessName: '',
    phone: '',
    message: 'Hey, I would like to have more information.'
  });
  const [status, setStatus] = useState({
    code: '',
    message: ''
  });

  // Handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('');
    try {
        const response = await sendEmail(formData);
        setStatus({
          code: 'success',
          message: 'Email sent successfully!'
        });
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          businessName: '',
          phone: '',
          message: ''
        });
    } catch (error) {
      setStatus({
        code: 'error',
        message: `Ohh Snap! An error occurred while sending email.`
      });
    }
  };
 const handleDismissMessage = () => {
    setStatus({ code: '', message:''});
  };

  return (
    <Container fluid className='p-5' style={{backgroundColor: '#FEFAF6', color: 'black'}}>
    <Row className="justify-content-center p-5">
        <h2>Contact Us</h2>
        <hr style={{border: '2px solid #854941', width:'30%'}}/>
    </Row>
      <Row className="d-flex justify-content-center pb-3" style={{color:'#794c2f'}}>
        <Col md={8}>
          <Form onSubmit={handleSubmit}>
            {status.message && (
              <AlertDismissible alertContent={status.message} variant={status.code==='error'? "danger": "success"}  />
            )}
            <Row>
              <Col xs={12} md={6} lg={6}>
                <Form.Group controlId="formFirstName" className='pt-3'>
                <Form.Label className="d-flex justify-content-start" style={{alignSelf: 'start'}}>First Name</Form.Label>
                <Form.Control
                  className='form-control-mordern'
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  required
                />
              </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Group controlId="formLastName" className='pt-3'>
                <Form.Label className="d-flex justify-content-start">Last Name</Form.Label>
                <Form.Control
                  className='form-control-mordern'
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </Form.Group>
              </Col>
            </Row>
            
            <Form.Group controlId="formEmail" className='pt-3'>
              <Form.Label className="d-flex justify-content-start">Email address</Form.Label>
              <Form.Control
                className='form-control-mordern'
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formBusinessName" className='pt-3'>
              <Form.Label className="d-flex justify-content-start">Business Name</Form.Label>
              <Form.Control
                className='form-control-mordern'
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                placeholder="Enter your business name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className='pt-3'>
              <Form.Label className="d-flex justify-content-start">Phone Number</Form.Label>
              <Form.Control
                className='form-control-mordern'
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </Form.Group>

            <Form.Group controlId="formMessage" className='pt-3'>
              <Form.Label className="d-flex justify-content-start">Message</Form.Label>
              <Form.Control
                className='form-control-mordern'
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
                placeholder="Enter your message(optional)"
              />
            </Form.Group>
            <Button style={{alignSelf:'center', justifySelf:'center', width: '80%', backgroundColor: '#794c2f'}} className='mt-5' variant="outline-light" type="submit">
                Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default App_ContactForm;
