import AnimationLoader from '../common/AnimationLoader';
import './Features.css'
import { Container, Row, Col, Card } from 'react-bootstrap';
import data from '../../app-data.json';
import { MdHeight } from 'react-icons/md';
import { height } from '@mui/system';


const FeatureCard = ({title, content, animationFile}) =>{
    return (
    <Card style={{ width: '18rem' , border: 'none'}} className='p-5'>
        <AnimationLoader style={{width: '200px', height: '200px'}} animationFile={animationFile}/>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {content}
        </Card.Text>
      </Card.Body>
    </Card>
    );
}

const App_Feature = () => {
return(
    <Container fluid className="justify-content-center"> 
        <Row className='p-5 justify-content-center'>
            <h3>{data.features.title}</h3>
            <hr style={{width: '30%', border: '2px solid #854941'}}/>
        </Row>
        <Row className="justify-content-center">
        {
            data.features.content.map((element,i) => (
                <Col xs={12} sm={6} md={4} lg={4} className="d-flex justify-content-center">
                    <FeatureCard className="justify-content-center" title={element.title} content={element.description} animationFile={element.animationFile}/> 
                </Col>
            ))
        }
        </Row>
    </Container>
);
};


export default App_Feature;