import React from 'react';
import './Footer.css'; // Import the CSS file for styling

const App_Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} MochaRewards. All rights reserved.</p>
    </footer>
  );
};

export default App_Footer;
