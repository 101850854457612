import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Nav } from 'react-bootstrap';
import { FaArrowRight, FaBars } from 'react-icons/fa'; // Corrected import path
import { Link } from 'react-scroll';
import './Navbar.css'; // Import the CSS file

const navbarStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  color: '#fff',
  zIndex: 1020,
};

function App_Navbar() {
  return (
    <Navbar expand="lg" variant="dark" style={navbarStyle} sticky="top">
      <Container fluid>
        <Navbar.Brand style={{ paddingLeft: '3px' }} className="d-flex align-items-center" href="#home">
          <img
            alt="Mocha Rewards Logo"
            src="favicon.png"
            width="50"
            height="50"
            className="d-inline-block align-top"
          />
          <h1 style={{ padding: '3px', color: 'white' }}>
            Mocha <span style={{ color: '#794c2f' }}>Rewards</span>
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" aria-label="Toggle navigation">
          <FaBars style={{ color: 'white' }} /> {/* Custom icon for the toggler */}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Link to="contact" smooth={true} duration={500}>
              <Button
                style={{ color: 'white' }}
                variant="outline-secondary"
                size="lg"
                className="mt-3 m-2"
                aria-label="Book a Demo"
              >
                Book a Demo <FaArrowRight />
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default App_Navbar;